import React from "react";
import Cookies from "js-cookie";
import Head from "../layout/Header";
import AdminSider from "./AdminSider";
import { Redirect } from "react-router-dom";

import "../../assets/admin.sass";

class AdminOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAdmin: Cookies.get("admin") === process.env.REACT_APP_ADMIN_KEY,
    };
  }

  componentDidMount() {
    // Api.get("api/")
  }

  handleSubmit() {
    // Api.post("api/login")
  }

  render() {
    return (
      <div>
        {this.state.isAdmin ? (
          <div className="admin_container">
            <AdminSider />
            <div className="admin_content">
              <p>Admin</p>
            </div>
          </div>
        ) : (
          <Redirect to="/admin" />
        )}
      </div>
    );
  }
}

export default AdminOverview;
