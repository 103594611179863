import React from "react";
import Head from "./layout/Header";

import pf from "../images/pf.jpg"
import { Col, Row, Button } from "antd";

import "../assets/about.sass"
import { Link } from "react-router-dom";
import { InstagramOutlined } from "@ant-design/icons";

class About extends React.Component {
  render() {
    return (
      <div>
        <Head />
        <div className="content">
            <Row>
                <img className="pf_img" src={pf} />
            </Row>
            <Row align="middle" className="about_container">
                <Col span={24}>
                <h1>Joran Timmerman</h1>
                <p>Joran Timmerman is a photographer located in Amsterdam, The Netherlands.</p>
                <p>Feel free to reach out to me.</p>

                <Row align="middle">
                    <Col span={2} offset={10}>
                        <Button>
                            <Link className="contact_link" to="/contact">Contact</Link>
                        </Button>
                    </Col>
                    <Col span={2}>
                    <Button type="text" href="https://www.instagram.com/jorantimmermanphotography/">
                        <InstagramOutlined style={{ fontSize: '20px' }} size="large" className="teamSocialIcon" />
                    </Button>
                    </Col>
                </Row>
                </Col>
            </Row>
        </div>
      </div>
    );
  }
}

export default About;
