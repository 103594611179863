import React from "react";
import HomepageHeader from "./layout/HomepageHeader";
import HomepageMenu from "./layout/HomepageMenu";
import HomePageFooter from "./layout/HomepageFooter";
import background from "../images/IMG_1634.jpg";

import "../assets/homepage.sass";
// import API from "../Api.js"

class Homepage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  render() {
    if (this.state.width > 1050) {
      return (
        <div
          className="container"
          style={{ backgroundImage: `url(${background})` }}
        >
          <HomepageHeader />
          <HomePageFooter />
        </div>
      );
    } else {
      return (
        <div
          className="container"
          style={{ backgroundImage: `url(${background})` }}
        >
          <HomepageMenu />
          <HomePageFooter />
        </div>
      );
    }
  }
}

export default Homepage;
