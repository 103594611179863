import React from "react";
import Cookies from "js-cookie";
import Head from "../layout/Header";
import AdminSider from "./AdminSider";
import { Redirect } from "react-router-dom";
import { Form, Upload, Modal, Button, Input, DatePicker } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import Api from "../../Api";

import "../../assets/admin.sass";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

class AdminUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAdmin: Cookies.get("admin") === process.env.REACT_APP_ADMIN_KEY,
      fileList: [],
      previewVisible: false,
      previewImage: "",
      previewTitle: "",
    };
  }

  componentDidMount() {
    // Api.get("api/admin/test")
    // console.log(this.props.match.params.user_param)
  }

  async saveImages(values) {
    console.log(values);
    // var formData = new FormData();
    // formData.append("FileForm", this.state.fileList[0].originFileObj)
    // formData.append("fileName", "testnaam")

    // await Api.post("/api/admin/upload", formData)
    //   .then(function (response) {
    //     console.log(response)
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   })
  }

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };

  handleChange = ({ fileList }) => this.setState({ fileList });

  dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  onChange(date, datestring) {}

  render() {
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );

    return (
      <div>
        {this.state.isAdmin ? (
          <div className="admin_container">
            <AdminSider />
            <div className="admin_content">
              <p>Upload images</p>
              <Form
                name="upload_form"
                labelCol={{ span: 2 }}
                wrapperCol={{ span: 22 }}
                onFinish={this.saveImages}
              >
                <Form.Item
                  label="Location"
                  name="Location"
                  // rules={[{ required: true, message: 'Please input a location!' }]}
                >
                  <Input />
                </Form.Item>
                {/* <Form.Item
                  label="Date"
                  name="Date"
                  // rules={[{ required: true, message: 'Please input a date!' }]}
                >
                  <DatePicker />
                </Form.Item> */}
                <Form.Item
                  label="file"
                  name="file"
                  // rules={[{ required: true, message: 'Please input a image!' }]}
                >
                  <Upload
                    listType="picture-card"
                    // fileList={this.state.fileList}
                    onPreview={this.handlePreview}
                    onChange={this.handleChange}
                  >
                    {this.state.fileList.length >= 1 ? null : uploadButton}
                  </Upload>
                  <Modal
                    visible={this.state.previewVisible}
                    title={this.state.previewTitle}
                    footer={null}
                    onCancel={this.handleCancel}
                  >
                    <img
                      alt="example"
                      style={{ width: "100%" }}
                      src={this.state.previewImage}
                    />
                  </Modal>
                </Form.Item>
                <Form.Item wrapperCol={{ span: 5, offset: 2 }}>
                  <Button type="primary" htmlType="submit">
                    Store images
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        ) : (
          <Redirect to="/admin" />
        )}
      </div>
    );
  }
}

export default AdminUpload;
