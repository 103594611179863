import React from "react";
import "../../assets/footer.sass";

class HomePageFooter extends React.Component {
  render() {
    return (
      <div className="homepage_footer">
        <p>Copyright © 2022 Joran Timmerman's. All Rights Reserved.</p>
      </div>
    );
  }
}

export default HomePageFooter;
