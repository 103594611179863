import React from "react";
import Head from "./layout/Header";
import { Form, Input, Button, notification } from "antd";
    import Api from "../Api";
import { Link } from "react-router-dom";

class Contact extends React.Component {
  layout = {
    labelCol: {
      span: 24,
      lg: { span: 4 },
    },
    wrapperCol: {
      span: 24,
      lg: { span: 20 },
    },
  };

  tailLayout = {
    wrapperCol: {
      lg: {
        offset: 4,
        span: 16,
      },
    },
  };

  handleSubmit(values) {
    var today = new Date();
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    values.date = date;
    var e = null;


    let url = "mailto:"
        + values.email
        + "?subject="
        + values.subject
        + "&body="
        + values.message
    window.open(url)
    // Api.post("api/contact", values).catch(function (error) {
    //   console.log(error);
    //   e = error;
    // });

    // if (e) {
    //   notification.error({
    //     message: "Failed to send",
    //     description: "Your contact request failed, please try again!",
    //   });
    // } else {
    //   notification.success({
    //     message: "Succesfully Send",
    //     description: "Your contact request has been send succesfully!",
    //   });
    // }
  }

  render() {
    return (
      <div>
        <Head />

        <div className="content">
          <h1>Get in touch</h1>
          <p>
            Connect me on <Link to="https://www.instagram.com/jorantimmermanphotography/">Instagram</Link> or send me a contact request down below!
          </p>

          <Form
            {...this.layout}
            name="contact_form"
            initialValues={{ remember: true }}
            onFinish={this.handleSubmit}
          >
            <Form.Item
              label="Name"
              name="username"
              rules={[{ required: true, message: "Please fill in your name!" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Email"
              name="email"
              rules={[
                { required: true, message: "Please fill in your email!" },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Subject"
              name="subject"
              rules={[{ required: true, message: "Please fill in a subject!" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Message"
              name="message"
              rules={[{ required: true, message: "Please fill in a message!" }]}
            >
              <Input.TextArea />
            </Form.Item>

            <Form.Item {...this.tailLayout}>
              <Button type="primary" htmlType="submit">
                Send
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}

export default Contact;
