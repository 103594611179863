import React from "react";
import Cookies from "js-cookie";
import Forbidden from "../Forbidden";
import AdminOverview from "./AdminOverview";
// import Api from "../../Api"

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAdmin: Cookies.get("admin") === process.env.REACT_APP_ADMIN_KEY,
    };
  }

  componentDidMount() {}

  render() {
    return <div>{this.state.isAdmin ? <AdminOverview /> : <Forbidden />}</div>;
  }
}

export default Admin;
