import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "antd";
import logo from "../../images/logo_white.png";
import "../../assets/homepage.sass";

class HomepageHeader extends React.Component {
  render() {
    return (
      <Row className="navbar">
        <Col span={6} className="navlinks">
          <div className="navlink1">
            <Link className="route" to="/portfolio">
              Portfolio
            </Link>
          </div>
          <div className="navlink2">
            <Link className="route" to="/about">
              About
            </Link>
          </div>
        </Col>
        <Col span={12} className="navlinks">
          <img className="logoheader" alt="logo" src={logo} />
        </Col>
        <Col span={6} className="navlinks">
          <div className="navlink1">
            <a
              className="route"
              href="https://www.instagram.com/jorantimmermanphotography/"
            >
              Instagram
            </a>
          </div>
          <div className="navlink2">
            <Link className="route" to="/contact">
              Contact
            </Link>
          </div>
        </Col>
      </Row>
    );
  }
}

export default HomepageHeader;
