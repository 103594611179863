import React from "react";
import Homepage from "./components/Homepage";
import Portfolio from "./components/Portfolio";
import About from "./components/About";
import Contact from "./components/Contact";
import NotFound from "./components/NotFound";
import Admin from "./components/admin/Admin";
import AdminContact from "./components/admin/AdminContact";
import AdminUpload from "./components/admin/AdminUpload";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./assets/index.sass";
import "./assets/pages.sass";

class App extends React.Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/">
            <Homepage />
          </Route>
          <Route path="/portfolio">
            <Portfolio />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/admin/contact">
            <AdminContact />
          </Route>
          <Route path="/admin/upload">
            <AdminUpload />
          </Route>
          <Route path="/admin">
            <Admin />
          </Route>
          <Route>
            <NotFound />
          </Route>
        </Switch>
      </Router>
    );
  }
}

export default App;
