// SideMenu.js
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import classnames from "classnames";
import { Row } from "antd";

class SideMenu extends Component {
  render() {
    const { isMenuActive, onOverLayClick, location } = this.props;

    const sideMenuClasses = classnames("side-menu", {
      "side-menu--active": isMenuActive,
    });
    const sideMenuContentClasses = classnames("side-menu__content", {
      "side-menu__content--active": isMenuActive,
    });

    return (
      <aside className={sideMenuClasses}>
        <div className="side-menu__overlay" onClick={onOverLayClick} />
        <div className={sideMenuContentClasses}>
          <div>
            <Row>
              <div className="hb_item">
                <NavLink className={`hb_dropdownitem`} to="/">
                  Home
                </NavLink>
              </div>
            </Row>
            <Row>
              <div className="hb_item">
                <NavLink
                  className={`hb_dropdownitem ${
                    location === "portfolio" ? "selected" : ""
                  }`}
                  to="/portfolio"
                >
                  Portfolio
                </NavLink>
              </div>
            </Row>
            <Row>
              <div className="hb_item">
                <NavLink
                  className={`hb_dropdownitem ${
                    location === "about" ? "selected" : ""
                  }`}
                  to="/about"
                >
                  About
                </NavLink>
              </div>
            </Row>

            <Row>
              <div className="hb_item">
                <a
                  className="hb_dropdownitem"
                  href="https://www.instagram.com/jorantimmermanphotography/"
                >
                  Instagram
                </a>
              </div>
            </Row>
            <Row>
              <div className="hb_item">
                <NavLink
                  className={`hb_dropdownitem ${
                    location === "contact" ? "selected" : ""
                  }`}
                  to="/contact"
                >
                  Contact
                </NavLink>
              </div>
            </Row>
          </div>
        </div>
      </aside>
    );
  }
}

export default SideMenu;
