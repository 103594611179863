import React from "react";
import { Link } from "react-router-dom";

class Forbidden extends React.Component {
  render() {
    return (
        <div>
            <h1>403 - Forbidden!</h1>
            <Link to="/">Go Home</Link>
        </div>
    )
  }
}

export default Forbidden;
