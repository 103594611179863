import React from "react";
import Cookies from "js-cookie";
import Head from "../layout/Header";
import AdminSider from "./AdminSider";
import { Redirect } from "react-router-dom";
import { Table, Space, Button } from "antd";
import Api from "../../Api";

import "../../assets/admin.sass";

class AdminContact extends React.Component {
  columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Name",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
    },
    {
      title: "Reply",
      key: "reply",
      render: (text, record) => {
        return (
          <Space size="middle">
            <Button
              type="primary"
              target="_blank"
              href={
                "https://mail.google.com/mail/u/0/?view=cm&fs=1&to=" +
                record.Email +
                "&su=" +
                record.Subject +
                "&body=Contact submission: " +
                record.Message +
                "\n\n"
              }
            >
              Reply
            </Button>
          </Space>
        );
      },
    },
    {
      title: "Delete",
      key: "delete",
      render: (text, record) => {
        return (
          <Space size="middle">
            <Button
              danger
              onClick={(e) => this.deleteContactRequest(record.key)}
            >
              delete
            </Button>
          </Space>
        );
      },
    },
  ];

  constructor(props) {
    super(props);

    this.state = {
      isAdmin: Cookies.get("admin") === process.env.REACT_APP_ADMIN_KEY,
      data: null,
    };
  }

  async componentDidMount() {
    this.getContactRequests();
  }

  async deleteContactRequest(id) {
    await Api.delete("/api/contact/" + id)
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });

    this.getContactRequests();
  }

  async getContactRequests() {
    let data = null;

    await Api.get("api/contact")
      .then(function (response) {
        data = response.data;
      })
      .catch(function (error) {
        console.log(error);
      });

    for (let i = 0; i < data.length; i++) {
      data[i].key = data[i].contactId.toString();
    }

    this.setState({ data: data });
  }

  render() {
    return (
      <div>
        {this.state.isAdmin ? (
          <div className="admin_container">
            <AdminSider />
            <div className="admin_content">
              <h1>Contact Requests</h1>
              <p>Overview of the submitted contact form requests.</p>
              <Table columns={this.columns} dataSource={this.state.data} />
            </div>
          </div>
        ) : (
          <Redirect to="/admin" />
        )}
      </div>
    );
  }
}

export default AdminContact;
