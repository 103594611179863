import React from "react";
import { Row, Col } from "antd";
import { Link } from "react-router-dom";

import logo from "../../images/logo_black.png";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";

import "../../assets/header.sass";
import SideMenu from "./SideMenu";

class Head extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      location: window.location.href.split("/")[3],
      width: 0,
      height: 0,
      collapsed: false,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ collapsed: false })
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  toggleCollapsed() {
    this.setState({ collapsed: !this.state.collapsed });
  }

  render() {
    if (this.state.width > 1150) {
      return (
        <div>
          <div className="nav">
            <Row className="navigation">
              <Col span={6} xxl={8}>
                <div className="item">
                  <Link className="link" to="/">
                    Home
                  </Link>
                </div>
                <div className="item">
                  <Link
                    className={`link ${
                      this.state.location === "portfolio" ? "selected" : ""
                    }`}
                    to="/portfolio"
                  >
                    Portfolio
                  </Link>
                </div>
              </Col>
              <Col span={12} xxl={8}>
                <div className="header_logo_container">
                  <img className="header_logo" alt="logo" src={logo} />
                </div>
              </Col>
              <Col span={6} xxl={8}>
                <div className="item">
                  <Link
                    className={`link ${
                      this.state.location === "about" ? "selected" : ""
                    }`}
                    to="/about"
                  >
                    About
                  </Link>
                </div>
                <div className="item">
                  <Link
                    className={`link ${
                      this.state.location === "contact" ? "selected" : ""
                    }`}
                    to="/contact"
                  >
                    Contact
                  </Link>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <SideMenu
            location={this.state.location}
            isMenuActive={this.state.collapsed}
            onOverLayClick={() => this.toggleCollapsed()}
          />
          <Row>
            <Col
              xs={{ span: 4 }}
              md={{ span: 2 }}
              onClick={() => this.toggleCollapsed()}
            >
              <button className="hamburger_menubutton">
                {this.state.collapsed ? (
                  <MenuFoldOutlined style={{ color: "black" }} />
                ) : (
                  <MenuUnfoldOutlined style={{ color: "black" }} />
                )}
              </button>
            </Col>
            <Col xs={{ span: 20 }} md={{ span: 22 }} className="hamburger_logo">
              <img alt="logo" src={logo} />
            </Col>
          </Row>
        </div>
      );
    }
  }
}

export default Head;
