import React from "react";
import "../../assets/footer.sass";

class Footer extends React.Component {
  render() {
    return (
      <div className="footer">
        <p>Copyright © 2023 Joran Timmerman's. All Rights Reserved.</p>
      </div>
    );
  }
}

export default Footer;
