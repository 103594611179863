import React from "react";

import PhotoAlbum from "react-photo-album";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/dist/styles.css";

// import optional lightbox plugins
import Slideshow from "yet-another-react-lightbox/dist/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/dist/plugins/thumbnails";
import "yet-another-react-lightbox/dist/plugins/thumbnails/thumbnails.css";

import Head from "./layout/Header";
import Footer from "./layout/Footer";

import "../assets/portfolio.sass";

import img0058_LR from "../images/photos/IMG_0058_LR.jpg";
import img0076_LR from "../images/photos/IMG_0076-LR.jpg";
import img0793 from "../images/photos/IMG_0793.jpg";
import img0813 from "../images/photos/IMG_0813.jpg";
import img0823 from "../images/photos/IMG_0823.jpg";
import img1266 from "../images/photos/IMG_1266.jpg";
import img1328 from "../images/photos/IMG_1328.jpg";
import img1634 from "../images/photos/IMG_3698LR2.jpg";
import img1637 from "../images/photos/IMG_1637.jpg";

// const photos = [
//   { src: img0058_LR, width: 4752, height: 3168, title: "TEST" },
//   { src: img0076_LR, width: 4752, height: 3168 },
//   { src: img0793, width: 5184, height: 3456 },
//   { src: img0813, width: 5184, height: 3456 },
//   { src: img0823, width: 5184, height: 3456 },
//   { src: img1266, width: 5184, height: 3456 },
//   { src: img1328, width: 5155, height: 3437 },
//   { src: img1637, width: 5184, height: 3456 },
//   { src: img1634, width: 3184, height: 4752  },
// ];

class Portfolio extends React.Component {
  constructor(props) {
    super(props);
    this.state = { index: -1, photos: [] };
  }

  componentDidMount() {
    fetch("https://www.flickr.com/services/rest/?method=flickr.photosets.getPhotos&api_key=" + process.env.REACT_APP_FLICKR_CONSUMER_KEY + "&photoset_id=72177720309281123&user_id=197137762%40N03&format=json&nojsoncallback=1")
    .then((res) =>
        res.json()
    )
    .then((data) => {
        this.CreateLayout(data, 3184, 4752)

        fetch("https://www.flickr.com/services/rest/?method=flickr.photosets.getPhotos&api_key=" + process.env.REACT_APP_FLICKR_CONSUMER_KEY + "&photoset_id=72177720309233843&user_id=197137762%40N03&format=json&nojsoncallback=1")
        .then((res) =>
            res.json()
        )
        .then((data) => {
            this.CreateLayout(data, 5184, 3456)
        });
    });
  }

  CreateLayout(photos, width, height) {
    let photos_list = this.state.photos

    photos.photoset.photo.forEach(photo => {
        let value = {
            src: "https://live.staticflickr.com/" + photo.server + "/" + photo.id + "_" + photo.secret + ".jpg",
            width: width,
            height: height
        }

        photos_list = this.Prepend(value, photos_list)
    });

    this.setState({photos: photos_list})
  }

  Prepend(value, array) {
    var newArray = array.slice();
    newArray.unshift(value);
    return newArray;
  }

  render() {
    return (
      <div>
        <Head />
        <div className="portfolio_container">
          <PhotoAlbum
            columns={(containerWidth) => {
              if (containerWidth < 768) return 1;
              if (containerWidth < 992) return 2;
              if (containerWidth < 1200) return 3;
              return 4;
            }}
            layout="masonry"
            photos={this.state.photos}
            onClick={(event, photo, index) => {
              this.setState({ index: index });
            }}
          />
          <Lightbox
            slides={this.state.photos}
            open={this.state.index >= 0}
            index={this.state.index}
            plugins={[Slideshow, Thumbnails]}
            close={() => this.setState({ index: -1 })}
          />
        </div>
        <Footer />
      </div>
    );
  }
}

export default Portfolio;
