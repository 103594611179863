import React from "react";
import { Row, Col } from "antd";
import { NavLink } from "react-router-dom";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import logo from "../../images/logo_white.png";
import "../../assets/homepage.sass";

class HomepageMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = { collapsed: false };
  }

  componentDidMount() {
    this.toggleCollapsed();
  }

  toggleCollapsed() {
    this.setState({ collapsed: !this.state.collapsed });
  }

  render() {
    return (
      <div>
        <Row>
          <Col
            xs={{ span: 4 }}
            md={{ span: 2 }}
            onClick={() => this.toggleCollapsed()}
          >
            <button className="menubutton">
              {!this.state.collapsed ? (
                <MenuFoldOutlined style={{ color: "#b3b3b3" }} />
              ) : (
                <MenuUnfoldOutlined style={{ color: "#b3b3b3" }} />
              )}
            </button>
          </Col>
          <Col xs={{ span: 20 }} md={{ span: 22 }} className="logo">
            <img alt="logo" src={logo} />
          </Col>
        </Row>
        <div className="dropdown">
          {!this.state.collapsed ? (
            <Row className="dropdown_list">
              <Col span={24} className="dropdown_list_item">
                <NavLink className="dropdownitem" to="/portfolio">
                  Portfolio
                </NavLink>
              </Col>
              <Col span={24} className="dropdown_list_item">
                <NavLink className="dropdownitem" to="/about">
                  About
                </NavLink>
              </Col>
              <Col span={24} className="dropdown_list_item">
                <a
                  className="dropdownitem"
                  href="https://www.instagram.com/jorantimmermanphotography/"
                >
                  Instagram
                </a>
              </Col>
              <Col span={24} className="dropdown_list_item">
                <NavLink className="dropdownitem" to="/contact">
                  Contact
                </NavLink>
              </Col>
            </Row>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  }
}

export default HomepageMenu;
